import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { navigate } from 'gatsby-link';

import Layout from '../components/layout';
import SEO from '../components/seo';
import flockLogo from '../images/logo.svg';
import flockScreen from '../images/flock-design.png';
import flockScreenTwo from '../images/flock-screen-2.png';
import flockScreenWorkout from '../images/flock-screen-workout.png';

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;

    img {
        width: 150px;
    }
`;

const BodyContent = styled.div`
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5;

    .value-prop {
        font-size: 20px;
    }

    iframe {
        width: 100%;
        height: 460px;
        border: 0;
    }

    p {
        font-size: 16px;
    }

    h2 {
        font-weight: 600;
        font-size: 21px;
        margin-top: 2rem;
    }

    .images {
        text-align: center;
    }

    img {
        width: 100%;
        max-width: 500px;
    }

    ul {
        padding-left: 1.2rem;
        margin-bottom: 2rem;

        li {
            margin-bottom: 1rem;
            font-size: 16px;
        }
    }

    small {
        margin-bottom: 0.5rem;
        font-size: 12px;
        display: block;
    }

    a {
        color: black;
    }

    form {
        margin-bottom: 2rem;

        input[type='email'],
        input[type='text'] {
            width: 100%;
            display: block;
            padding: 10px 13px;
            border: 1px solid black;
            border-radius: 3px;
            box-shadow: none;
        }

        button {
            background-color: #61ffbd;
            border-radius: 3px;
            border: solid 1px black;
            font-size: 1rem;
            padding: 10px 20px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
`;

const CallOut = styled.p`
    background-color: #d6ffee;
    padding: 13px 20px;
    border-radius: 3px;
    margin-bottom: 2rem;
`;

const BlockStrong = styled.strong`
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
`;

function encode(data: { [key: string]: string }): string {
    return Object.keys(data)
        .map(
            key =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
        )
        .join('&');
}

const IndexPage = () => {
    const [botField, setBotField] = useState<string>('');
    const [emailField, setEmailField] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [instagramField, setInstagramField] = useState<string>('');

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();
            const form = e.target;

            if (botField.length > 0) {
                return;
            }

            fetch('https://api.sendgrid.com/v3/marketing/contacts', {
                method: 'PUT',
                headers: {
                    Authorization:
                        'Bearer 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',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    list_ids: ['16e59e90-a1b8-4536-b26e-133dcdc34a24'],
                    contacts: [
                        {
                            email: emailField,
                            first_name: firstName,
                            unique_name: instagramField,
                        },
                    ],
                }),
            })
                .then(() => navigate(form.getAttribute('action')))
                .catch(error => alert(error));
        },
        [botField, emailField, firstName, instagramField],
    );

    return (
        <Layout>
            <SEO
                title="The app for fitness professionals"
                description="FLOCK.fitness is here to change how fitness professionals engage with their clients."
            />

            <LogoContainer>
                <Link to="/">
                    <img src={flockLogo} />
                </Link>
            </LogoContainer>

            <BodyContent>
                <p className="value-prop">
                    FLOCK allows you to create unique workout content for your
                    subscribers and get paid with a monthly subscription model,
                    all in one place.
                </p>

                <CallOut>
                    Join the waiting list and we’ll give you FLOCK commission
                    free for 3 months.
                </CallOut>

                <form
                    method="POST"
                    action="/success"
                    name="interest"
                    onSubmit={handleSubmit}
                >
                    <div style={{ display: 'none' }}>
                        <input
                            name="bot-field"
                            type="text"
                            onChange={e => setBotField(e.target.value)}
                        />
                    </div>

                    <input
                        type="email"
                        name="email-address"
                        placeholder="Enter your email"
                        onChange={e => setEmailField(e.target.value)}
                        required
                    />
                    <small>
                        We’ll only use this to contact you about the launch of
                        FLOCK
                    </small>

                    <input
                        type="text"
                        name="firstName"
                        placeholder="Enter your first name"
                        onChange={e => setFirstName(e.target.value)}
                        required
                    />
                    <small>
                        This will only be used in our correspondence with you
                    </small>

                    <input
                        type="text"
                        name="instagram"
                        placeholder="Enter your Instagram username"
                        onChange={e => setInstagramField(e.target.value)}
                    />
                    <small>(Optional)</small>

                    <div>
                        <button type="submit">Join the list</button>
                    </div>
                </form>

                <div className="images">
                    <img src={flockScreen} alt="Screenshot of new Flock app" />

                    <img
                        src={flockScreenWorkout}
                        alt="Screenshot of new Flock app before starting a workout"
                    />
                </div>

                <h2>Get paid for the content you create</h2>

                <p>
                    We’re building a new platform that allows you to author
                    workout programmes, engage with customers and get paid, all
                    in one place. FLOCK will give you the tools to create unique
                    content for your subscribers and monetise your efforts with
                    a monthly subscription model.
                </p>

                <h2>Benefits to you:</h2>

                <ul>
                    <li>
                        Flock is all the benefits of your own content
                        distribution platform without the headache of creating
                        your own.
                    </li>

                    <li>
                        Ability to offer something new and different to your
                        followers.
                    </li>

                    <li>
                        Getting paid for your content; creating your own
                        personal brand and channel which reduces your reliance
                        on sponsorship revenue.
                    </li>
                </ul>

                <h2>How it works:</h2>

                <ul>
                    <li>
                        <BlockStrong>
                            Set up your own space on FLOCK
                        </BlockStrong>{' '}
                        Create <strong>your</strong> own FLOCK, set the amount
                        you want to charge for your content (per month) and
                        upload your content/workouts.
                    </li>

                    <li>
                        <BlockStrong>
                            Invite people to subscribe to your FLOCK and start
                            earning
                        </BlockStrong>
                        Use your unique URL to invite people to subscribe to
                        your FLOCK, giving them exclusive access to the content
                        you create.
                    </li>

                    <li>
                        <BlockStrong>
                            Engage with your FLOCK further{' '}
                            <span style={{ color: '#d4366a' }}>
                                (future release)
                            </span>
                        </BlockStrong>
                        We plan to enable you to engage further with your FLOCK
                        by enabling 1-on-1 live sessions for that personal
                        approach (you will be able to charge an additional fee
                        for this).
                    </li>
                </ul>

                <div className="images">
                    <img
                        src={flockScreenTwo}
                        alt="Screenshot of new Flock app"
                    />
                </div>

                <h2>Earn from those you invite</h2>

                <p>
                    And it gets better. Not only can you earn from your
                    subscribers, but also from other creators you invite to join
                    the platform. Creators will receive a small share of their
                    commission.
                </p>

                <h2>Our transparent pricing</h2>

                <p>
                    In order to survive and thrive, the FLOCK platform will
                    charge a commision on the charges you make to your
                    customers.
                </p>

                <p>
                    20% commission. <strong>No additional costs.</strong>
                </p>

                <h2>Interested?</h2>

                <p>
                    We’re working hard on the platform at the moment and we aim
                    to have the the first version released early 2021.
                </p>

                <p>
                    Register your interest by joining the waiting list and when
                    we launch you’ll receive 3 months commission free:
                </p>

                <CallOut>
                    Join the waiting list and we’ll give you FLOCK commission
                    free for 3 months.
                </CallOut>

                <form
                    method="POST"
                    action="/success"
                    name="interest"
                    onSubmit={handleSubmit}
                >
                    <div style={{ display: 'none' }}>
                        <input
                            name="bot-field"
                            type="text"
                            onChange={e => setBotField(e.target.value)}
                        />
                    </div>

                    <input
                        type="email"
                        name="email-address"
                        placeholder="Enter your email"
                        onChange={e => setEmailField(e.target.value)}
                        required
                    />
                    <small>
                        We’ll only use this to contact you about the launch of
                        FLOCK
                    </small>

                    <input
                        type="text"
                        name="firstName"
                        placeholder="Enter your first name"
                        onChange={e => setFirstName(e.target.value)}
                        required
                    />
                    <small>
                        This will only be used in our correspondence with you
                    </small>

                    <input
                        type="text"
                        name="instagram"
                        placeholder="Enter your Instagram username"
                        onChange={e => setInstagramField(e.target.value)}
                    />
                    <small>(Optional)</small>

                    <div>
                        <button type="submit">Join the list</button>
                    </div>
                </form>

                <h2>Want to invest?</h2>

                <p>Join us on the FLOCK journey.</p>

                <p>
                    We’re looking for individuals who want to join our team,
                    help spread the word about FLOCK and give us the financial
                    boost needed to make FLOCK the next big thing (Capital at
                    risk).
                </p>
                <p>
                    Have over 1m followers on Instagram? Get in touch with us
                    directly to explore more free-commission options. There are
                    5 places available.
                </p>
                <p>
                    Email our founder{' '}
                    <a href="mailto:james@flock.fitness">james@flock.fitness</a>{' '}
                    to express an interest in getting involved.
                </p>
            </BodyContent>
        </Layout>
    );
};

export default IndexPage;

import React from 'react';
import { Normalize } from 'styled-normalize';
import styled from 'styled-components';

import GlobalStyles from './global-styles';
import Footer from './footer';

const AppContainer = styled.div`
    padding: 20px;
`;

const Main = styled.main``;

const Layout = ({ children }: LayoutProps) => (
    <AppContainer>
        <Normalize />
        <GlobalStyles />

        <Main>{children}</Main>

        <Footer />
    </AppContainer>
);

interface LayoutProps {
    children: React.ReactNode;
}

export default Layout;

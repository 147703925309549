import React from 'react';
// import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

const FooterContainer = styled.footer``;

const Copyright = styled.p`
    text-align: center;
    font-size: 0.8rem;
    margin: 2rem 0;

    a {
        color: black;
        display: block;
    }
`;

const Footer = () => {
    // const data = useStaticQuery(graphql`
    //     query SiteTitleQuery {
    //         site {
    //             siteMetadata {
    //                 title
    //                 siteUrl
    //             }
    //         }
    //     }
    // `)

    return (
        <FooterContainer>
            <Copyright>
                Copyright FLOCK.fitness 2020. All rights reserved.{' '}
                <a href="privacy">Privacy policy</a>
            </Copyright>
        </FooterContainer>
    );
};

export default Footer;

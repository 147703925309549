import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body {
        color: #222;
        font-family: Roboto,"Helvetica Neue",Arial,sans-serif;
        font-weight: 400;
        line-height: 1.5em;
        -moz-osx-font-smoothing: grayscale;
    }
`;

export default GlobalStyles;
